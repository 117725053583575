import React from 'react';
import Navbar from '../components/Layouts/Navbar';

import Footer from '../components/Layouts/Footer';

import { NextSeo } from 'next-seo';
import StartseiteMainBanner from '../components/Startseite/StartseiteMainBanner';
import ParallaxMainBanner from '../components/Startseite/StartseiteParallax';
import StartseiteCard from '../components/Startseite/StartseiteCard';
import StartseiteDienstleistung from '../components/Startseite/StartseiteDienstleistung';
import StartseiteFeatures from '../components/Startseite/StartseiteFeatures';
import StartseiteFaq from '../components/Startseite/StartseiteFaq';
import StartseiteCaseStudies from '../components/Startseite/StartseiteCaseStudies';
import ÜberunsMitgliedschaften from '../components/About/ÜberunsMitgliedschaften';


const Index = () => {
    return (
        <>
            <NextSeo
                title="Simplify Data - Ihr Partner für innovative IT-Lösungen und moderne Technologien"
                description="Simplify Data bietet maßgeschneiderte IT-Lösungen für Unternehmen aller Größen. Vertrauen Sie auf moderne, innovative Technologien für jede Herausforderung."
                canonical="https://simplify-data.de/"
                openGraph={{
                    url: 'https://simplify-data.de/',
                    title: 'Simplify Data - Ihr Partner für innovative IT-Lösungen und moderne Technologien',
                    description: 'Simplify Data ist Ihr starker IT-Partner mit maßgeschneiderten, innovativen Lösungen für jedes Unternehmen. Wir bieten Lösungen von der Webentwicklung bis hin zu Business Analytics.',
                    images: [
                        {
                            url: 'https://simplify-data.de/images/simplify-data.png',
                            width: 1200,
                            height: 1200,
                            alt: 'Simplify Data - Ihr Partner für innovative IT-Lösungen',
                            type: 'image/jpeg',
                        },
                    ],
                    siteName: 'Simplify Data - Innovative IT-Lösungen',
                }}
                twitter={{
                    handle: '@simplify_data',
                    site: '@simplify_data',
                    cardType: 'summary_large_image',
                }}
                additionalMetaTags={[
                    {
                        name: 'robots',
                        content: 'index, follow',
                    },
                    {
                        name: 'keywords',
                        content: 'IT-Dienstleistungen, Webentwicklung, Business Analytics, Softwarelösungen, Digitalisierung, IT-Beratung, Simplify Data',
                    },
                ]}
                additionalLinkTags={[
                    {
                        rel: 'icon',
                        href: 'https://simplify-data.de/favicon.png',
                    },
                ]}
            />

            <Navbar />
            {/* Static <StartseiteMainBanner />  */}
            <ParallaxMainBanner />
            <span className='water-edge' style={{ height: 100, width: "100%", backgroundColor: "#00011D" }} />


            {/* "Our Loving Clients" -> dienstleistungen: aufführung der Namen */}
            <StartseiteDienstleistung />

            {/* 3 Auflistungen + Bild */}
            <StartseiteFeatures />

            {/* "4 Card Eigenshaften" ->  */}
            <StartseiteCard />

            {/* "case studies" ->  */}
            <StartseiteCaseStudies />

            <ÜberunsMitgliedschaften />

            <Footer />
        </>
    );
}

export default Index;